import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import { Check, Close } from "@mui/icons-material";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";

import { selectReportsByMultiReportId, useGetReportsQuery } from "fond/api";
import { MultiReport, Report, Store } from "fond/types";
import { formatFraction, formatNumber } from "fond/utils/number";
import { AgGrid, BlockSpinner } from "fond/widgets";

import { useStarred } from "../ItemMenu/hooks/useStarred";
import ReportRowMenu from "../ItemMenu/ReportRowMenu";
import EntityTypeCellRenderer from "../ProjectList/EntityCellRenderer";
import RowMenuCellRenderer from "../ProjectList/RowMenuCellRenderer";
import StatusCellRenderer from "../ProjectList/StatusCellRenderer";

interface IProps {
  multiReport: MultiReport;
}

const reportStatusEntries = {
  PENDING: null, // Should not be observable through the API
  IN_PROGRESS: {
    text: "Generating",
    icon: <CircularProgress size={18} color="info" />,
  },
  COMPLETE: {
    text: "Generated",
    icon: <Check />,
  },
  ERROR: {
    text: "Error",
    icon: <Close />,
  },
};

const gridOptions: GridOptions = {
  defaultColDef: {
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    sortable: true,
  },
  domLayout: "autoHeight",
  rowClass: "no-alternate-background",
  animateRows: true,
  suppressGroupRowsSticky: true,
  suppressContextMenu: true,
  sideBar: false,
  rowGroupPanelShow: "never",
  pagination: true,
  paginationPageSize: 50,
  loadingOverlayComponent: BlockSpinner,
};

const SubareaReportList: React.FC<IProps> = ({ multiReport }: IProps) => {
  const { isLoading, isFetching } = useGetReportsQuery(multiReport.ID);
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);
  const [menuEntity, setMenuEntity] = useState<Report | undefined>(undefined);
  const reports = useSelector((state: Store) => selectReportsByMultiReportId(state, multiReport.ID) || []);
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const { starred } = useStarred();
  const isStarred = useCallback((id: string) => starred.includes(id), [starred]);

  const openRowMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity?: Report) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntity(entity);
  }, []);

  const closeRowMenu = () => setMenuAnchor(undefined);

  const revenueFilterParams = {
    defaultOption: "greaterThan",
    maxNumConditions: 1,
    filterOptions: ["greaterThan", "greaterThanOrEqual", "lessThan", "lessThanOrEqual", "inRange"],
  };

  const costFilterParams = {
    ...revenueFilterParams,
    defaultOption: "lessThan",
  };

  const columns: ColDef[] = [
    {
      field: "Name",
      headerName: "Name",
      flex: 3,
      minWidth: 200,
      cellRenderer: EntityTypeCellRenderer,
      cellRendererParams: {
        currentUsername,
        isStarred,
      },
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
      cellRendererSelector: ({ data }) => {
        if (data?.EntityType === "report" && data?.Status !== null) {
          return {
            params: { status: data.Status, statusEntries: reportStatusEntries },
            component: StatusCellRenderer,
          };
        }
        return undefined;
      },
    },
    {
      field: "Npv",
      headerName: "NPV ($)",
      width: 150,
      valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.Npv),
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: revenueFilterParams,
    },
    {
      field: "NetCost",
      headerName: "Cost ($)",
      width: 150,
      valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.NetCost),
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: costFilterParams,
    },
    {
      field: "Irr",
      headerName: "IRR (%)",
      width: 150,
      valueGetter: (params: ValueGetterParams) => formatFraction(params.data?.Irr),
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: revenueFilterParams,
    },
    {
      field: "CostPerPassing",
      headerName: "Cost per passing ($)",
      width: 150,
      valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.CostPerPassing),
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: costFilterParams,
    },
    {
      field: "menu",
      headerName: "",
      cellRenderer: RowMenuCellRenderer,
      cellRendererParams: {
        onClick: openRowMenu,
      },
      sortable: false,
      resizable: false,
      type: "rightAligned",
      width: 74,
    },
  ];

  return (
    <>
      <Box mt={5} mb={2}>
        <Typography variant="h3" component="span" fontWeight={700}>
          Subarea summary
        </Typography>
      </Box>
      <Paper square>
        <AgGrid
          columnDefs={columns}
          rowData={reports}
          gridOptions={gridOptions}
          variant="outlined"
          containerProps={{ flexGrow: 1 }}
          loading={isLoading || isFetching}
          size="large"
        />
      </Paper>
      {menuEntity && <ReportRowMenu report={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />}
    </>
  );
};

export default SubareaReportList;
