import { Configuration, ConfigurationUpsert, MultiProjectArea, Report } from "fond/types";
import { FilterConfiguration } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

import { scoringPalette } from "../helper";

import { layerConfigTemplate, sublayerTemplate } from "./configuration";
import { labelTemplate, layerStyles } from "./subareaConfiguration";

export const generateCityReportMapConfiguration = (areas: MultiProjectArea[], reports: Report[]): Configuration => {
  const data = cityReportConfiguration(areas, reports);
  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [],
    Type: "MapLayerConfig",
  };
};

/**
 * Create the city report configuration.
 */
export const cityReportConfiguration = (areas: MultiProjectArea[], reports: Report[]): ConfigurationUpsert => {
  const NpvByAreaId: Record<string, number | null> = {};
  for (const area of areas) {
    NpvByAreaId[area.ID] = reports.find((report) => report.MultiProjectArea?.ID === area.ID)?.Npv || null;
  }

  const layerConfigId = "city_report_layer";
  const sublayerIds: string[] = [];
  const descendants = areas.flatMap(({ ID, Name }) => {
    const Npv = NpvByAreaId[ID];
    const color = !Npv ? "grey" : Npv > 0 ? scoringPalette[100] : scoringPalette[0];
    const sublayerId = `subarea-by-npv-${ID}-${Npv}`;
    const filter: FilterConfiguration = { Mapbox: ["==", ["get", "boundaryId"], ID], Type: "expression" };
    const styles = layerStyles(sublayerId, color, filter, 0.3);
    const label = labelTemplate(`${sublayerId}-label`, filter);
    const sublayerConfig = sublayerTemplate(sublayerId, "", layerConfigId, Name, filter, [label, ...styles]);
    sublayerIds.push(sublayerId);
    return [label, ...styles, label, sublayerConfig];
  });

  const config = layerConfigTemplate(layerConfigId, "", "Npv", [], sublayerIds);
  return [...descendants, config];
};
