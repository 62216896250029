import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { useGetVersionConfigQuery } from "fond/api";
import { generateCityReportMapConfiguration } from "fond/cityPlanner/configuration/cityReportConfiguration";
import { Map } from "fond/cityPlanner/Map";
import BaseMapButtons from "fond/map/BaseMapButtons";
import { MapContext } from "fond/map/MapProvider";
import { MultiProject, Report, Store } from "fond/types";
import { isValidBoundingBox } from "fond/utils";
import { selectLayersFromConfig, selectStylesFromConfig } from "fond/utils/configurations";

interface IProps {
  multiProject: MultiProject;
  reports?: Report[] | null;
}

const CityReportMap: React.FC<IProps> = ({ multiProject, reports }) => {
  const { map } = useContext(MapContext);
  const { project } = useSelector((state: Store) => state.project.projects[multiProject.ID] || {});
  const [layerVisibilities, setLayerVisibilities] = useState<Record<string, boolean>>({});
  useGetVersionConfigQuery(multiProject.ID);

  const cityMapConfiguration = useMemo(
    () => (reports ? generateCityReportMapConfiguration(multiProject.Areas, reports) : null),
    [reports, multiProject.Areas]
  );

  const layerConfigs = useMemo(
    () => (cityMapConfiguration ? selectLayersFromConfig(cityMapConfiguration, ["SUBLAYER"]) : []),
    [cityMapConfiguration]
  );

  const styles = useMemo(() => (cityMapConfiguration ? selectStylesFromConfig(cityMapConfiguration) : null), [cityMapConfiguration]);

  useEffect(() => {
    setLayerVisibilities(
      Object.fromEntries(
        layerConfigs.map((layerConfig) => {
          return [layerConfig.ID, true];
        })
      )
    );
  }, [layerConfigs]);

  const onClickZoomToDesignHandler = () => {
    if (project.BoundingBox && isValidBoundingBox(project.BoundingBox)) {
      map?.fitBounds(project.BoundingBox);
    }
  };

  const onClickZoomInHandler = () => {
    map?.zoomIn();
  };

  const onClickZoomOutHandler = () => {
    map?.zoomOut();
  };

  return layerConfigs && styles ? (
    <Map layerView={layerVisibilities} styles={styles} layerConfigs={layerConfigs} showFooter={false}>
      <Box className="right-sidebar-section">
        <div />
        <BaseMapButtons
          editMode="none"
          onClickZoomToDesign={onClickZoomToDesignHandler}
          onClickZoomIn={onClickZoomInHandler}
          onClickZoomOut={onClickZoomOutHandler}
          currentLocationButton={false}
        />
      </Box>
    </Map>
  ) : null;
};

export default CityReportMap;
